import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './CityWidget.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import torontoImage from '../assets/images/toronto.webp';
import bramptonImage from '../assets/images/brampton.webp';
import oakvilleImage from '../assets/images/oakville.webp';
import mississaugaImage from '../assets/images/mississauga.webp';
import ottawaImage from '../assets/images/ottawa.jpg';
import hamiltonImage from '../assets/images/hamilton.webp';
import londonImage from '../assets/images/london.webp';
import markhamImage from '../assets/images/markham.webp';
import vaughanImage from '../assets/images/vaughan.webp';
import ajaxImage from '../assets/images/Ajax.jfif'; // ensure this file is in this path
import auroraImage from '../assets/images/Aurora.JPG'; // ensure this file is in this path
import barrieImage from '../assets/images/Barrie.jpg'; // ensure this file is in this path
import brockImage from '../assets/images/Brock.JPG'; // ensure this file is in this path
import burlingtonImage from '../assets/images/Burlington.jpg'; // ensure this file is in this path
import caledonImage from '../assets/images/Caledon.jpg'; // ensure this file is in this path
import cambridgeImage from '../assets/images/Cambridge.jpg'; // ensure this file is in this path
import claringtonImage from '../assets/images/Clarington.jpg'; // ensure this file is in this path
import collingwoodImage from '../assets/images/Collingwood.jpg'; // ensure this file is in this path
import erinImage from '../assets/images/Erin.jpg'; // ensure this file is in this path
import georginaImage from '../assets/images/Georgina.jpg'; // ensure this file is in this path
import grimsbyImage from '../assets/images/Grimsby.jpg'; // ensure this file is in this path
import innisfilImage from '../assets/images/Innisfil.JPG'; // ensure this file is in this path
import kawarthaLakesImage from '../assets/images/Kawartha Lakes.jfif'; // ensure this file is in this path
import kingImage from '../assets/images/King.png'; // ensure this file is in this path
import kingstonImage from '../assets/images/Kingston.JPG'; // ensure this file is in this path
import lincolnImage from '../assets/images/Lincoln.jpg'; // ensure this file is in this path
import midlandImage from '../assets/images/Midland.jpg'; // ensure this file is in this path
import miltonImage from '../assets/images/Milton.jpg'; // ensure this file is in this path
import newmarketImage from '../assets/images/Newmarket.jpg'; // ensure this file is in this path
import niagaraFallsImage from '../assets/images/Niagara Falls.jpg'; // ensure this file is in this path
import niagaraOnTheLakeImage from '../assets/images/Niagara-On-The-Lake.jpg'; // ensure this file is in this path
import orangevilleImage from '../assets/images/Orangeville.jpg'; // ensure this file is in this path
import orilliaImage from '../assets/images/Orillia.jfif'; // ensure this file is in this path
import oshawaImage from '../assets/images/Oshawa.jfif'; // ensure this file is in this path
import peterboroughImage from '../assets/images/Peterborough.jpg'; // ensure this file is in this path
import pickeringImage from '../assets/images/Pickering.jpg'; // ensure this file is in this path
import portColborneImage from '../assets/images/Port Colbourne.jpg'; // ensure this file is in this path
import tinyImage from '../assets/images/Tiny.jfif'; // ensure this file is in this path
import wasagaBeachImage from '../assets/images/Wasaga Beach.jpg'; // ensure this file is in this path
import waterlooImage from '../assets/images/Waterloo.jfif'; // ensure this file is in this path
import wellandImage from '../assets/images/Welland.jpg'; // ensure this file is in this path
import whitbyImage from '../assets/images/Whitby.jpg'; // ensure this file is in this path
import whitchurchImage from '../assets/images/Whitchurch.jpg'; // ensure this file is in this path



const cities = [
  { name: 'Toronto', image: torontoImage },
  { name: 'Ottawa', image: ottawaImage },
  { name: 'Mississauga', image: mississaugaImage },
  { name: 'Brampton', image: bramptonImage },
  { name: 'Hamilton', image: hamiltonImage },
  { name: 'London', image: londonImage },
  { name: 'Markham', image: markhamImage },
  { name: 'Vaughan', image: vaughanImage },
  { name: 'Oakville', image: oakvilleImage },
  { name: 'Ajax', image: ajaxImage },
  { name: 'Aurora', image: auroraImage },
  { name: 'Barrie', image: barrieImage },
  { name: 'Brock', image: brockImage },
  { name: 'Burlington', image: burlingtonImage },
  { name: 'Caledon', image: caledonImage },
  { name: 'Cambridge', image: cambridgeImage },
  { name: 'Clarington', image: claringtonImage },
  { name: 'Collingwood', image: collingwoodImage },
  { name: 'Erin', image: erinImage },
  { name: 'Georgina', image: georginaImage },
  { name: 'Grimsby', image: grimsbyImage },
  { name: 'Innisfil', image: innisfilImage },
  { name: 'King', image: kingImage },
  { name: 'Kingston', image: kingstonImage },
  { name: 'Lincoln', image: lincolnImage },
  { name: 'Midland', image: midlandImage },
  { name: 'Milton', image: miltonImage },
  { name: 'Newmarket', image: newmarketImage },
  { name: 'Niagara-on-the-Lake', image: niagaraOnTheLakeImage },
  { name: 'Orangeville', image: orangevilleImage },
  { name: 'Orillia', image: orilliaImage },
  { name: 'Oshawa', image: oshawaImage },
  { name: 'Peterborough', image: peterboroughImage },
  { name: 'Pickering', image: pickeringImage },
  { name: 'Tiny', image: tinyImage },
  { name: 'Waterloo', image: waterlooImage },
  { name: 'Welland', image: wellandImage },
  { name: 'Whitby', image: whitbyImage },
  { name: 'Whitchurch', image: whitchurchImage },
];

const CityWidget = () => {
  const sliderRef = useRef(null);
  const [cityWidth, setCityWidth] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateCityWidth = () => {
    const cityElement = document.querySelector('.custom-city-column');
    if (cityElement) {
      const computedStyle = window.getComputedStyle(cityElement);
      const margin = parseFloat(computedStyle.marginLeft) + parseFloat(computedStyle.marginRight);
      const fullWidth = cityElement.offsetWidth + margin;
      setCityWidth(fullWidth);
    }
  };

  const snapToNearestCity = () => {
    if (sliderRef.current) {
      const scrollLeft = sliderRef.current.scrollLeft;
      const nearestCityIndex = Math.round(scrollLeft / cityWidth);
      sliderRef.current.scrollTo({
        left: nearestCityIndex * cityWidth,
        behavior: 'smooth',
      });
    }
  };

  const handleResize = () => {
    const newWidth = window.innerWidth;
    if ((newWidth >= 768 && windowWidth < 768) || (newWidth < 768 && windowWidth >= 768)) {
      sliderRef.current.scrollTo({ left: 0, behavior: 'auto' });
    }
    setWindowWidth(newWidth);
    updateCityWidth();
    snapToNearestCity();
  };

  useEffect(() => {
    updateCityWidth();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [cityWidth, windowWidth]);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -(cityWidth),
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: cityWidth + 0.05,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className='custom-city-container'>
      <h2>Search Available Homes by <span className='partners-text'>City</span></h2>
      <div className='divider'></div>
      <div className='city-slider-wrapper'>
        <button onClick={scrollLeft} className='slider-button left'>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        <div className='city-slider' ref={sliderRef}>
          {cities.map((city, index) => (
            <Link
            to={`/listings?city=${city.name.charAt(0).toUpperCase() + city.name.slice(1).toLowerCase()}`}

              key={index}
              className='custom-city-column'
            >
              <div
                className='city-image-container'
                style={{ backgroundImage: `url(${city.image})` }}
              >
                <div className='city-name-overlay'>
                  <span className='city-name'>{city.name}</span>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <button onClick={scrollRight} className='slider-button right'>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default CityWidget;
