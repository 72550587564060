import React, { useState, useEffect, useRef } from 'react';
import axios from '../services/axios';
import './ListingWidget.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ListingWidget = () => {
  const [listings, setListings] = useState([]);
  const sliderRef = useRef(null);
  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const response = await axios.post('/listings/bulk'); // Fetching listings without specifying MLS numbers
        setListings(response.data);
      } catch (error) {
        console.error('Error fetching listings:', error);
      }
    };

    fetchListings();
  }, []);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -300, // Adjust this value for how much you want to scroll on each click
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 300, // Adjust this value for how much you want to scroll on each click
        behavior: 'smooth',
      });
    }
  };

  const handleMouseDown = (e) => {
    isDraggingRef.current = true;
    sliderRef.current.classList.add('dragging');
    startXRef.current = e.pageX - sliderRef.current.offsetLeft;
    scrollLeftRef.current = sliderRef.current.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (!isDraggingRef.current) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startXRef.current) * 2; // Adjust the drag speed by multiplying the walk value
    sliderRef.current.scrollLeft = scrollLeftRef.current - walk;
  };

  const handleMouseUp = () => {
    isDraggingRef.current = false;
    sliderRef.current.classList.remove('dragging');
  };

  const handleTouchStart = (e) => {
    isDraggingRef.current = true;
    sliderRef.current.classList.add('dragging');
    startXRef.current = e.touches[0].pageX - sliderRef.current.offsetLeft;
    scrollLeftRef.current = sliderRef.current.scrollLeft;
  };

  const handleTouchMove = (e) => {
    if (!isDraggingRef.current) return;
    e.preventDefault();
    const x = e.touches[0].pageX - sliderRef.current.offsetLeft;
    const walk = (x - startXRef.current) * 2; // Adjust the drag speed by multiplying the walk value
    sliderRef.current.scrollLeft = scrollLeftRef.current - walk;
  };

  const handleTouchEnd = () => {
    isDraggingRef.current = false;
    sliderRef.current.classList.remove('dragging');
  };

  const formatPrice = (price, propertyType) => {
    const formattedPrice = `$${Number(price).toLocaleString()}`;
    return propertyType.toLowerCase() === 'rental' ? `${formattedPrice}/month` : formattedPrice;
  };

  if (!listings.length) {
    return <div>Loading...</div>;
  }

  return (
    <div className='custom-listing-container'>
      <h2>Featured <span className='partners-text'>Listings</span></h2>
      <div className='divider'></div>
      <div className='slider-wrapper'>
        <button onClick={scrollLeft} className='slider-button left'>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        <div
          className='slider'
          ref={sliderRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp} // Ensure dragging stops if the user leaves the slider
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {listings.concat(listings).map((listing, index) => {
            const photoUrls = listing.photo_urls ? listing.photo_urls : [];
            const firstPhotoUrl = photoUrls.length > 0 ? photoUrls[0] : '';

            // Format the address for the URL
            const formattedAddress = listing.address.toLowerCase().replace(/\s+/g, '-');

            return (
              <Link
  to={`/listing/${listing.ml_num}/${formattedAddress}`}
  key={index}
  className='custom-listing-column'
>
  <div className='image-container'>
    {firstPhotoUrl ? (
      <img src={firstPhotoUrl} alt='Listing Image' />
    ) : (
      <p>Image not available</p>
    )}
    
    {/* Overlay the listing info */}
    <div className='overlay-icons'>
      <span className='overlay-button'>
        {listing.address}
      </span>
      <div className='beds-baths-container'>
        <span className='overlay-button'>
          {listing.bedrooms} Beds
        </span>
        <span className='overlay-button'>
          {listing.bathrooms} Baths
        </span>
      </div>
      <span className='overlay-button'>
        {formatPrice(listing.price, listing.property_type)}
      </span>
    </div>
    
    {/* Button for cash back */}
    <Link to="/contact" className="list-button">
      Buy and Get $5000 Cash Back
    </Link>
  </div>
</Link>



            );
          })}
        </div>
        <button onClick={scrollRight} className='slider-button right'>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default ListingWidget;
